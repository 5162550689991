import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { WorkBoxProvider } from 'react-workbox';
import { Spin, Icon } from 'antd';

import 'react-app-polyfill/ie11';

// tslint:disable-next-line no-implicit-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
// tslint:disable-next-line no-implicit-dependencies
import 'react-vis/dist/style.css';

import App from './App';
import configureStore from './store/configureStore';
import IntlProvider from './components/IntlProvider';
import constants from './utils/constants';

import './assets/styles/charts.less';
import './assets/styles/AntOverride.less';

Spin.setDefaultIndicator(<Icon type="loading" spin />);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    return ReactDOM.render(
        <Provider store={store}>
            <IntlProvider>
                <BrowserRouter>
                    {/* <WorkBoxProvider interval={constants.SERVICE_WORKER_UPDATE_CHECK_INTERVAL}> */}
                        <Component />
                    {/* </WorkBoxProvider> */}
                </BrowserRouter>
            </IntlProvider>
        </Provider>,
        document.getElementById('root') as HTMLElement,
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
