import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import DeckGL, { PathLayer, IconLayer } from 'deck.gl';

import {
    DeliveryTour, Delivery, Coordinates, NeerbyTag, GeoJson, getSimplerDeliveryStatus, PermissionRight,
} from '../../store/api/types';
import { AuthUser, getUser } from '../../store/reducers/auth';

import { MapBase } from '../../components/map';
import { DetailsStateById, MainReducerState } from '../../store/reducers';
import { MapBaseState } from '../../components/map/MapBase';
import MapMarkerClusterLayer from '../../components/map/MapMarkerClusterLayer';
import iconSpriteMapping from '../../utils/mapSpriteMapping';

import mapSprite from '../../assets/images/map-sprite.png';
import { isUserAllowed } from '../../utils/permissions';
import { rotationFromCoordinates } from '../../utils/coordinates';

export interface DeliveryTourMarker {
    coordinates: Coordinates;
    data: Delivery;
    index: number;
}

interface DeliveryTourMapProps extends InjectedIntlProps {
    deliveryTour?: DeliveryTour;
    loading: DetailsStateById<DeliveryTour>['loading'];
    neerbyTags?: NeerbyTag[];
    matchedRoutes?: GeoJson[];
    onTaskClick: (d: Delivery) => void;
    user: AuthUser;
}

interface DeliveryTourMapState {
    hasDoneFirstMapFit: boolean;
    taskMarkers: DeliveryTourMarker[];
}

function mapTaskMarkers(deliveries?: any[]) {
    return (deliveries || []).map((delivery: Delivery, index: number) => {
        if (delivery.address && delivery.address.coordinates) {
            return {
                coordinates: delivery.address.coordinates,
                data: delivery,
                index,
            };
        }
        return undefined;
    }).filter(Boolean);
}

class DeliveryTourMap extends Component<DeliveryTourMapProps, DeliveryTourMapState> {
    public mapRef = createRef<any>();
    public state: DeliveryTourMapState = {
        hasDoneFirstMapFit: false,
        taskMarkers: [],
    };

    public componentWillMount() {
        const { deliveryTour } = this.props;
        const taskMarkers = (deliveryTour ? mapTaskMarkers(deliveryTour.deliveries) : []) as DeliveryTourMarker[];
        this.setState({
            taskMarkers,
        }, () => {
            if (!this.state.hasDoneFirstMapFit) {
                this.setState({ hasDoneFirstMapFit: true }, () => {
                    this.fitMapOnMarkers(taskMarkers);
                });
            }
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 1500);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2500);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 3000);
    }

    public componentDidUpdate(prevProps: DeliveryTourMapProps, prevState: DeliveryTourMapState) {
        const { deliveryTour, loading } = this.props;
        const { hasDoneFirstMapFit } = this.state;

        if (!hasDoneFirstMapFit || prevProps.deliveryTour !== deliveryTour) { // } && prevProps.loading && !loading && this.state.taskMarkers) {
            const taskMarkers = (deliveryTour ? mapTaskMarkers(deliveryTour.deliveries) : []) as DeliveryTourMarker[];

            console.log('componentDidUpdate:', taskMarkers)
            this.setState({
                taskMarkers,
            }, () => {
                if (!this.state.hasDoneFirstMapFit) {
                    this.setState({ hasDoneFirstMapFit: true }, () => {
                        this.fitMapOnMarkers(taskMarkers);
                    });
                } else {
                    this.fitMapOnMarkers(taskMarkers);
                }
            });
        }
    }

    public fitMapOnMarkers = (taskMarkers: DeliveryTourMarker[]) => {
        if (this.mapRef.current && taskMarkers.length > 1) {
            this.mapRef.current.fitToBounds(taskMarkers.map((m) => m.coordinates));
        }
    }

    public renderLayers = (viewport: MapBaseState['viewport']) => {
        const { deliveryTour, neerbyTags, matchedRoutes, onTaskClick, user } = this.props;
        const { taskMarkers } = this.state;
        const layers = [];

        if (deliveryTour && deliveryTour.agency && deliveryTour.agency.coordinates) {
            layers.push(new IconLayer({ 
                id: 'icon',
                data: [{
                    position: [
                        deliveryTour.agency.coordinates.longitude,
                        deliveryTour.agency.coordinates.latitude,
                    ],
                }],
                getIcon: (d: any) => 'agency',
                getSize: (d: any) => 35,
                iconAtlas: mapSprite,
                iconMapping: iconSpriteMapping,
            }));
        }

        let srcCoordinates: number[] = [ 0, 0 ];
        let vehiculeCoordinates: number[] = [ 0, 0 ];
        if (neerbyTags && isUserAllowed(user, 'mapDebug', PermissionRight.read)) {

            const coordinates = neerbyTags.filter((e) => e.location)
                .map((e) => [e.location.longitude, e.location.latitude, 0.01]);
            const layer = new PathLayer({
                id: 'neerby-tags-path-layer',
                // @ts-ignore
                data: [{
                    path: coordinates,
                }],
                fp64: true,
                getColor: [202, 191, 255],
                miterLimit: 2,
                opacity: .05,
                widthMaxPixels: 25,
                widthMinPixels: 3,
                widthScale: 20,
            });

            layers.push(layer);

            // srcCoordinates = coordinates[coordinates.length - 2];
            // vehiculeCoordinates = coordinates[coordinates.length - 1];
        }

        if (matchedRoutes) {
            for (const index in matchedRoutes) {
                if (matchedRoutes[index]) {
                    const coordinates = matchedRoutes[index].coordinates
                        .map(([longitude, latitude]) => [longitude, latitude, 0.02]);
                    const layer = new PathLayer({
                        id: `matchedRoutes-path-layer-${index}`,
                        // @ts-ignore
                        data: [{
                            path: coordinates,
                        }],
                        fp64: true,
                        getColor: (matchedRoutes[index] as any).color || [104, 75, 255],
                        miterLimit: 2,
                        opacity: .9,
                        widthMaxPixels: 15,
                        widthMinPixels: 3,
                        widthScale: 10,
                    });

                    layers.push(layer);

                    srcCoordinates = coordinates[coordinates.length - 2];
                    vehiculeCoordinates = coordinates[coordinates.length - 1];
                }
            }
        }

        if (vehiculeCoordinates[0] !== 0 && vehiculeCoordinates[1] !== 0) {
            layers.push(new IconLayer({
                id: 'delivery-man',
                data: [{
                    position: [ vehiculeCoordinates[0], vehiculeCoordinates[1], 0.03 ],
                }],
                getColor: [0, 0, 0, 255],
                getIcon: (d: any) => 'truck',
                getSize: 54,
                iconAtlas: mapSprite,
                iconMapping: iconSpriteMapping,
                getAngle: rotationFromCoordinates(srcCoordinates, vehiculeCoordinates),
            }));
        }

        if (taskMarkers.length) {
            const layer = new MapMarkerClusterLayer({
                id: 'clustered-tasks-layer',
                // @ts-ignore
                data: taskMarkers,
                // @ts-ignore
                fp64: true,
                getIcon: (d: DeliveryTourMarker) => `pin-${getSimplerDeliveryStatus(d.data.status)}-${d.index + 1}`,
                getIconSize: (d: DeliveryTourMarker) => 35,
                getPosition: (d: DeliveryTourMarker) => [d.coordinates.longitude, d.coordinates.latitude],
                iconAtlas: mapSprite,
                iconMapping: iconSpriteMapping,
                onIconClick: (d: DeliveryTourMarker) => onTaskClick(d.data),
                onClusterClick: this.fitMapOnMarkers,
                pickable: true,
                sizeScale: 1,
            });

            layers.push(layer);
        }

        if (!layers.length) {
            return null;
        } else {
            return <DeckGL {...viewport} layers={layers} />;
        }
    }

    public render() {
        const { deliveryTour, intl } = this.props;

        return (
            <MapBase
                ref={this.mapRef}
                locale={intl.locale}
            >
                {(viewport) =>
                    deliveryTour && this.renderLayers(viewport)
                }
            </MapBase>
        );
    }
}

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default injectIntl(connect(
    mapStateToProps,
)(DeliveryTourMap));
