import * as React from 'react';
import { Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import { getRoute, RoutePathName } from '../routes';
import messages from './MainMenu.messages';
import {
    IconDashboard, IconAgencies, IconCustomer, IconDeliveryTour, IconDeliveryMen, IconMonitoring, IconUsers, IconRoles,
} from './icons';

import '../assets/styles/MainMenu.less';
import { Languages } from './IntlProvider';
import { User, PermissionRight } from '../store/api/types';
import { isUserAllowed } from '../utils/permissions';

interface MainMenuProps extends RouteComponentProps, InjectedIntlProps {
    user: User;
}

interface MainMenuState {
    collapsed: boolean;
}

class MainMenu extends React.Component<MainMenuProps, MainMenuState> {
    public state: MainMenuState = {
        collapsed: false,
    };

    public canSeeSupervisionMenu = () => {
        const { user } = this.props;
        const permissions = ['dashboard', 'agencies', 'customers', 'deliveryTours', 'deliveryMen'];

        // show menu even if permission is 'disabled'
        return permissions.some((permission: string) =>
            !!isUserAllowed(user, permission, PermissionRight.read),
        );
    }

    public canSeeAdministrationMenu = () => {
        const { user } = this.props;
        const permissions = ['users', 'roles', 'organizations'];

        // show menu even if permission is 'disabled'
        return permissions.some((permission: string) =>
            !!isUserAllowed(user, permission, PermissionRight.read),
        );
    }

    public render() {
        const { intl, location, user } = this.props;
        const locale: Languages = intl.locale as Languages;
        const deliveryTourListRoute = getRoute(locale, RoutePathName.deliveryTourList);
        let selectedKey = location.pathname;

        if (location.pathname.match(deliveryTourListRoute)) {
            selectedKey = deliveryTourListRoute;
        }

        // inside the Menu component we can't use custom components that's why we don't
        // use the 'Can' component

        return (
            <Menu
                id="main-menu"
                selectedKeys={[selectedKey]}
                theme="dark"
            >
                {/* {this.canSeeSupervisionMenu() && ( */}
                    <Menu.ItemGroup
                        title={<FormattedMessage {...messages.supervision} />}
                    >

                            <Menu.Item key={getRoute(locale, RoutePathName.dashboard)} disabled>
                                <Link to={getRoute(locale, RoutePathName.dashboard)} className="nav-text">
                                    <IconDashboard />
                                    <FormattedMessage {...messages.dashboard} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.agenciesList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.agenciesList)} className="nav-text">
                                    <IconAgencies />
                                    <FormattedMessage {...messages.agencies} />
                                </Link>
                            </Menu.Item>

                        <Menu.Item key={getRoute(locale, RoutePathName.tms)}>
                            <Link to={getRoute(locale, RoutePathName.tms)} className="nav-text">
                                <IconDeliveryTour />
                                    <FormattedMessage {...messages.tms} />
                            </Link>
                        </Menu.Item>
                        
                            <Menu.Item key={getRoute(locale, RoutePathName.customersList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.customersList)} className="nav-text">
                                    <IconCustomer />
                                    <FormattedMessage {...messages.customers} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.deliveryTourList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.deliveryTourList)} className="nav-text">
                                    <IconDeliveryTour />
                                    <FormattedMessage {...messages.deliveryTours} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.deliveryMenList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.deliveryMenList)} className="nav-text">
                                    <IconDeliveryMen />
                                    <FormattedMessage {...messages.deliveryMen} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.monitoring)} disabled>
                                <Link to={getRoute(locale, RoutePathName.monitoring)} className="nav-text">
                                    <IconMonitoring />
                                    <FormattedMessage {...messages.monitoring} />
                                </Link>
                            </Menu.Item>
                    </Menu.ItemGroup>
                {/* )} */}
                    <Menu.ItemGroup
                        title={<FormattedMessage {...messages.administration} />}
                    >
                            <Menu.Item key={getRoute(locale, RoutePathName.usersList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.usersList)} className="nav-text">
                                    <IconUsers />
                                    <FormattedMessage {...messages.users} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.roles)} disabled>
                                <Link to={getRoute(locale, RoutePathName.roles)} className="nav-text">
                                    <IconRoles />
                                    <FormattedMessage {...messages.roles} />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(locale, RoutePathName.organizationsList)} disabled>
                                <Link to={getRoute(locale, RoutePathName.organizationsList)} className="nav-text">
                                    <Icon type="profile" />
                                    <FormattedMessage {...messages.organizations} />
                                </Link>
                            </Menu.Item>
                    </Menu.ItemGroup>
            </Menu>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
});

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
        )(MainMenu),
    ),
);
