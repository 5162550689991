import * as React from 'react';
import { Drawer, Card, Tag, Icon, Avatar, Row, Col, List, Tooltip, Alert, Popover } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import {
    FormattedDate, FormattedMessage, FormattedTime, FormattedNumber, injectIntl, InjectedIntlProps,
} from 'react-intl';

import {
    Delivery, Parcel, getParcelType, DeliveryItem, getDeliveryItemStatusColor, getDeliveryItemStatus,
    getDeliveryItemType, getDeliveryType, DeliveryType, getDeliveryEventMessage, DeliveryEvent,
    Payment,
} from '../../store/api/types';

import '../../assets/styles/DeliveryDetailsDrawer.less';

import DeliveryTourSiderMessages from '../deliveryTours/DeliveryTourSider.messages';
import EmptyStateText from '../../components/typography/EmptyStateText';
import { FullAddress } from '../../components/FullAdress';
import { DescriptiveList } from '../../components/list';
import PriceDetails from '../../components/PriceDetails';
import avatar from '../../assets/images/avatar.jpg';
import avatar2x from '../../assets/images/avatar@2x.jpg';
import avatar3x from '../../assets/images/avatar@3x.jpg';
import CustomersListMessages from '../customers/CustomersList.messages';
import GenericMessages from '../../locale/Generic.messages';
import messages from './DeliveryDetailsDrawer.messages';

interface DeliveryDetailsDrawerProps extends DrawerProps, InjectedIntlProps {
    delivery?: Delivery;
}

interface DeliveryDetailsDrawerState {
    parcelTab: string;
    paymentTab: string;
}

class DeliveryDetailsDrawer extends React.Component<DeliveryDetailsDrawerProps, DeliveryDetailsDrawerState> {
    public state: DeliveryDetailsDrawerState = {
        parcelTab: '0',
        paymentTab: '0',
    };

    public onClose: DrawerProps['onClose'] = (e) => {
        this.setState({ parcelTab: '0' }, () => {
            if (typeof this.props.onClose === 'function') {
                this.props.onClose(e);
            }
        });
    }

    public onPaymentTabChange = (key: string) => {
        this.setState({ paymentTab: key });
    }

    public onParcelTabChange = (key: string) => {
        this.setState({ parcelTab: key });
    }

    public renderProfileCard = (delivery: Delivery) => {
        return (
            <Card className="profile-card">
                <>
                    <Card.Meta
                        avatar={
                            <Avatar
                                size="large"
                                src={avatar}
                                srcSet={`${avatar} 1x, ${avatar2x} 2x, ${avatar3x} 3x`}
                            />
                        }
                        title={delivery.contact.name}
                    />
                    <Row type="flex" justify="space-around" align="middle">
                        <Col xs={24}>
                            <div className="customer-profile-infos">
                                <Icon type="mail" />
                                <ul>
                                    {delivery.contact.email ? (
                                        <li>{delivery.contact.email}</li>
                                    ) : (
                                            <EmptyStateText>
                                                <FormattedMessage
                                                    {...CustomersListMessages.noEmail}
                                                />
                                            </EmptyStateText>
                                        )}
                                </ul>
                            </div>
                        </Col>
                        <Col xs={24}>
                            <div className="customer-profile-infos">
                                <Icon type="phone" />
                                <ul>
                                    {delivery.contact.phone ? (
                                        <li>{delivery.contact.phone}</li>
                                    ) : (
                                            <EmptyStateText>
                                                <FormattedMessage
                                                    {...CustomersListMessages.noPhone}
                                                />
                                            </EmptyStateText>
                                        )}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </>
            </Card>
        );
    }

    public renderDeliveryComments = (delivery: Delivery) => {
        return (
            <Card
                size="small"
                title={<FormattedMessage {...GenericMessages.comments} />}
            >
                {delivery.comment && Object.keys(delivery.comment).length ? (
                    <>
                        <p>
                            <strong>
                                <FormattedMessage
                                    {...DeliveryTourSiderMessages[
                                    getDeliveryEventMessage(DeliveryEvent.customerComment)
                                    ]}
                                />
                            </strong><br />
                            {
                                delivery.comment.customer || (
                                    <EmptyStateText>
                                        <FormattedMessage {...GenericMessages.noComments} />
                                    </EmptyStateText>
                                )
                            }
                        </p>
                        <p>
                            <strong>
                                <FormattedMessage
                                    {...DeliveryTourSiderMessages[
                                    getDeliveryEventMessage(DeliveryEvent.deliveryManComment)
                                    ]}
                                />
                            </strong><br />
                            {
                                delivery.comment.deliveryMan || (
                                    <EmptyStateText>
                                        <FormattedMessage {...GenericMessages.noComments} />
                                    </EmptyStateText>
                                )
                            }
                        </p>
                    </>
                ) : (
                        <EmptyStateText>
                            <FormattedMessage {...GenericMessages.noComments} />
                        </EmptyStateText>
                    )}
            </Card>
        );
    }

    public renderSignature = (delivery: Delivery) => {
        return (
            <Card
                size="small"
                title={<FormattedMessage {...GenericMessages.signature} />}
                cover={delivery.signature && delivery.signature.imageUrl ?
                    <img alt="signature" src={delivery.signature.imageUrl} /> :
                    null}
            >
                {delivery.signature && delivery.signature.imageUrl ? (
                    <FormattedMessage
                        {...GenericMessages.dateAndTime}
                        values={{
                            date: <FormattedDate value={delivery.signature.registeredAt} />,
                            time: <FormattedTime value={delivery.signature.registeredAt} />,
                        }}
                    />
                ) : (
                        <EmptyStateText>
                            <FormattedMessage
                                {...GenericMessages.noSignature}
                            />
                        </EmptyStateText>
                    )}
            </Card>
        );
    }

    public renderPaymentContent = () => {
        const { delivery, intl } = this.props;
        const { paymentTab } = this.state;

        if (!delivery) {
            return null;
        }

        const payment = delivery.payments[parseInt(paymentTab, 10)];

        const descriptiveListData = [{
            term: <FormattedMessage {...GenericMessages.reference} />,
            description: payment.reference,
        }, {
            term: <FormattedMessage {...GenericMessages.method} />,
            description: payment.method,
        }];

        return (
            <Row type="flex" justify="space-between">
                <Col style={{ flex: '1 0' }}>
                    <DescriptiveList
                        data={descriptiveListData}
                        style={{ marginBottom: 0 }}
                    />
                </Col>
                <Col style={{ marginLeft: 32 }}>
                    <strong>
                        {intl.formatNumber(payment.amount, {
                            style: 'currency',
                            currency: 'EUR',
                        })}
                    </strong>
                </Col>
            </Row>
        );
    }

    public renderPayments = (delivery: Delivery) => {
        const tabList = delivery.payments.map((payment: Payment, index: number) => ({
            key: `${index}`,
            tab: (
                <>
                    <FormattedMessage
                        {...GenericMessages.payment}
                        values={{ index, count: 1 }}
                    />
                    {` ${index + 1}`}
                </>
            ),
        }));

        return (
            <Card
                activeTabKey={this.state.paymentTab}
                extra={delivery.price ? (
                    <>
                        <span className="delivery-price">
                            <FormattedNumber
                                value={delivery.price.price}
                                style="currency"
                                currency="EUR"
                            />
                            <Popover
                                content={(
                                    <PriceDetails
                                        price={delivery.price}
                                    />
                                )}
                                placement="left"
                                trigger="click"
                            >
                                <Icon type="plus-circle" />
                            </Popover>
                        </span>
                    </>
                ) : null}
                onTabChange={this.onPaymentTabChange}
                tabList={tabList}
                title={(
                    <FormattedMessage
                        {...GenericMessages.price}
                    />
                )}
            >
                {this.renderPaymentContent()}
            </Card>
        );
    }

    public renderParcelItem = (item: DeliveryItem) => {
        const descriptiveListData = [{
            term: <FormattedMessage {...GenericMessages.reference} />,
            description: item.reference,
        }, {
            term: <FormattedMessage {...GenericMessages.barCodes} />,
            description: item.barCodes && item.barCodes.length ? (
                <ul>
                    {item.barCodes.map((barCode, index) => <li key={`barCode${index}`}>{barCode}</li>)}
                </ul>
            ) : (
                    <EmptyStateText>
                        <FormattedMessage {...GenericMessages.noData} />
                    </EmptyStateText>
                ),
        }];

        if (item.price) {
            if (item.price.price) {
                descriptiveListData.push({
                    term: <FormattedMessage {...GenericMessages.priceInclTax} />,
                    description: (
                        <FormattedNumber
                            value={item.price.price}
                            style="currency"
                            currency="EUR"
                        />
                    ),
                });
            }
            if (item.price.withoutTax) {
                descriptiveListData.push({
                    term: <FormattedMessage {...GenericMessages.priceWithoutTax} />,
                    description: (
                        <FormattedNumber
                            value={item.price.withoutTax}
                            style="currency"
                            currency="EUR"
                        />
                    ),
                });
            }
        }

        return (
            <List.Item
                extra={(
                    <Row type="flex" justify="center" align="top" gutter={16}>
                        <Col>
                            <Tag>
                                <FormattedMessage {...DeliveryTourSiderMessages[getDeliveryItemType(item.type)]} />
                            </Tag>
                        </Col>
                        <Col>
                            <Tag color={getDeliveryItemStatusColor(item.status)}>
                                <FormattedMessage {...DeliveryTourSiderMessages[getDeliveryItemStatus(item.status)]} />
                            </Tag>
                        </Col>
                    </Row>
                )}
            >
                <div className="parcel-item-list-item">
                    <List.Item.Meta
                        title={item.label}
                        description={
                            <DescriptiveList
                                data={descriptiveListData}
                                style={{ marginBottom: 0 }}
                            />
                        }
                    />
                    {item.anomaly && (
                        <Alert
                            message={(
                                <Row type="flex" justify="space-between">
                                    <Col style={{ flex: '1 0' }}>
                                        <FormattedMessage
                                            {...GenericMessages.anomalyOnDate}
                                            values={{
                                                date: (
                                                    <FormattedDate
                                                        value={item.anomaly.registeredAt}
                                                    />
                                                ),
                                                time: (
                                                    <FormattedTime
                                                        value={item.anomaly.registeredAt}
                                                    />
                                                ),
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Tag>
                                            <FormattedMessage
                                                {...DeliveryTourSiderMessages.deliveryItemAnomalyTypeDamaged}
                                            />
                                        </Tag>
                                    </Col>
                                </Row>
                            )}
                            description={(
                                <p>
                                    {item.anomaly.comment && (
                                        <>
                                            <strong>
                                                <FormattedMessage
                                                    {...GenericMessages.comments}
                                                />
                                            </strong><br />
                                            {item.anomaly.comment}
                                        </>
                                    )}
                                    {item.anomaly.imageUrl && (
                                        <>
                                            <br />
                                            <img
                                                alt="anomaly"
                                                src={item.anomaly.imageUrl}
                                            />
                                        </>
                                    )}
                                </p>
                            )}
                            type="error"
                            showIcon
                        />
                    )}
                </div>
            </List.Item>
        );
    }

    public renderParcels = () => {
        const { delivery } = this.props;
        const { parcelTab } = this.state;
        const parcelTabList = delivery && delivery.parcels.map((p: Parcel, index: number) => ({
            key: `${index}`,
            tab: (
                <FormattedMessage
                    {...GenericMessages.parcel}
                    values={{ index: `${p.number !== undefined ? p.number : ''}` }}
                />
            ),
        }));

        if (delivery && parcelTabList && delivery.items.filter((item) => !item.parcel).length) {
            parcelTabList.push({
                key: `${parcelTabList.length}`,
                tab: (
                    <FormattedMessage
                        {...messages.outOfParcel}
                    />
                ),
            });
        }
        let items: DeliveryItem[] = [];
        let parcelContent = null;
        let isExtra = false;

        if (!delivery) {
            return null;
        }

        const parcel = delivery.parcels[parseInt(parcelTab, 10)];

        if (!parcel) {
            isExtra = true;
        }

        if (!isExtra) {
            items = delivery.items.filter((item) => item.parcel === parcel.reference);

            const descriptiveListData = [{
                term: <FormattedMessage {...GenericMessages.reference} />,
                description: parcel.reference,
            }];

            if (parcel.tracking) {
                descriptiveListData.push({
                    term: <FormattedMessage {...messages.trackingNumber} />,
                    description: parcel.tracking,
                });
            }

            if (parcel.trackingPartner) {
                descriptiveListData.push({
                    term: <FormattedMessage {...messages.partnerTrackingNumber} />,
                    description: parcel.trackingPartner,
                });
            }

            parcelContent = (
                <Row type="flex" justify="space-between">
                    <Col style={{ flex: '1 0' }}>
                        <DescriptiveList
                            data={descriptiveListData}
                        />
                    </Col>
                    <Col>
                        <Tag>
                            <FormattedMessage {...DeliveryTourSiderMessages[getParcelType(parcel.type)]} />
                        </Tag>
                    </Col>
                </Row>
            );
        } else {
            items = delivery.items.filter((item) => !item.parcel);
        }

        return (
            <Card
                tabList={parcelTabList}
                activeTabKey={this.state.parcelTab}
                onTabChange={this.onParcelTabChange}
            >
                {parcelContent}
                <List
                    bordered
                    dataSource={items}
                    header={<FormattedMessage {...messages.parcelContent} />}
                    id="parcel-item-list"
                    itemLayout="horizontal"
                    renderItem={this.renderParcelItem}
                    size="large"
                />
            </Card>
        );
    }

    public render() {
        const { delivery, onClose, ...rest } = this.props;

        return (
            <Drawer
                closable
                onClose={this.onClose}
                placement="right"
                title={delivery ? (
                    <FormattedMessage
                        {...messages[`${getDeliveryType(delivery.type)}DetailsDrawerTitle`]}
                        values={{
                            reference: delivery.reference,
                            thirdParty: delivery.type === DeliveryType.thirdParty ?
                                `${delivery.partner ? delivery.partner.name : ''}` :
                                undefined,
                        }}
                    />
                ) : null}
                visible={!!delivery}
                width={758}
                {...rest}
            >
                {delivery ? (
                    <div className="delivery-details-drawer">
                        <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={12}>
                                {this.renderProfileCard(delivery)}
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card
                                    title={<FormattedMessage {...GenericMessages.address} values={{index: ''}} />}
                                >
                                    <FullAddress
                                        address={delivery.address}
                                        withLineBreak
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={12}>
                                {this.renderDeliveryComments(delivery)}
                            </Col>
                            <Col xs={24} sm={12}>
                                {this.renderSignature(delivery)}
                            </Col>
                        </Row>
                        {delivery.payments && !!delivery.payments.length && this.renderPayments(delivery)}
                        {this.renderParcels()}
                    </div>
                ) : null}
            </Drawer>
        );
    }
}

export default injectIntl(DeliveryDetailsDrawer);
