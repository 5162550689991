import * as React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import './assets/styles/App.less';
import './assets/styles/List.less';
import './assets/styles/Typography.less';
import './assets/styles/Form.less';

import { ProtectedRoute, Can } from './components/auth';
import MainLayout from './components/MainLayout';
import NotFound from './pages/notFound';
import Unauthorized from './pages/unauthorized';
import TMS from './pages/tms/TMS';

import Login from './pages/login';
import { Dashboard } from './pages/dashboard';
import { DeliveryTourDetails, DeliveryTourList } from './pages/deliveryTours';
import { UsersList } from './pages/users';
import { AgenciesList } from './pages/agencies';
import { DeliveryMenList, DeliveryMenDetails } from './pages/deliveryMen';
import { CustomersList } from './pages/customers';
import { OrganizationsList } from './pages/organizations';
import { Roles } from './pages/roles';
import { getRawRoute, RoutePathName } from './routes';
import { Languages } from './components/IntlProvider';
import CustomerDetails from './pages/customers/CustomerDetails';
import Monitoring from './pages/monitoring/Monitoring';
import { DeliveryTourStatus } from './store/api/types';

const App: React.SFC<InjectedIntlProps> = (props) => {
    const locale: Languages = props.intl.locale as Languages;
    return (
        <Router>
            <Switch>
                <MainLayout>
                    <Switch>
                        <TMS
                            deliveryTour={{
                                reference: 'DEMO_1234567890',
                                label: 'Démo TMS',
                                date: new Date().toISOString(),
                                status: DeliveryTourStatus.started,
                                deliveries: [],
                                scheduledEvents: [],
                                lastTag: {},
                                params: {},
                                meta: {},
                            }}
                            neerbyTags={[]}
                            matchedRoutes={[
                                { type: 'LineString', coordinates: [
                                    [ 2.334611, 49.152539 ],
                                    [ 2.334564, 49.152728 ],
                                    [ 2.334011, 49.152713 ],
                                    [ 2.333257, 49.152782 ],
                                    [ 2.333211, 49.153089 ],
                                ]},
                            ]}
                        />
                    </Switch>
                </MainLayout>
            </Switch>
        </Router>
    );
};

export default injectIntl(App);
