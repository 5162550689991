import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedDate, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Select } from 'antd';
import moment from 'moment';
import polyline from '@mapbox/polyline';
import * as flexiblePolyline from '../../utils/flexible-polyline';

import * as DeliveryToursActions from '../../store/actions/deliveryTours';
import { DeliveryTour, NeerbyTag, GeoJson, Delivery, DeliveryType, DeliveryStatus, Address, ParcelType, DeliveryItemType, DeliveryItemStatus, DeliveryItemSource } from '../../store/api/types';
import {
    getDeliveryTourById, getNeerbyTagsByDeliveryTourId, getMatchedRoutesByDeliveryTourId, getReads,
} from '../../store/reducers/deliveryTours';
import { MainReducerState, RequestState } from '../../store/reducers';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import listMessages from '../../locale/List.messages';
import { DeliveryTourMap, DeliveryTourSider } from '../deliveryTours';
import { getRoute, RoutePathName } from '../../routes';
import DeliveryTourMessages from '../deliveryTours/DeliveryTour.messages';
import { Languages } from '../../components/IntlProvider';
import SendMessagePopover from '../../components/SendMessagePopover';
import { Can } from '../../components/auth';

import '../../assets/styles/DeliveryTourDetails.less';
import DeliveryTourDeliveryManDetailsDrawer from '../deliveryTours/DeliveryTourDeliveryManDetailsDrawer';
import { IconDeliveryTour } from '../../components/icons';

import deliveryTourSample from './sample.json';
// import routePolyline from './polyline.json';


const loadType: any = {
    'FRAIS': ParcelType.fresh,
    'SURGELES': ParcelType.frozen,
    'PERMANENT': ParcelType.grocery,
};

const tourCountByDate = deliveryTourSample.reduce((res, tour) => ({ ...res, [tour.date]: true }), {});
const dates = Object.keys(tourCountByDate);

const tourCountByVehicles = dates.reduce((res, date) => {
    return { ...res, [date]: deliveryTourSample.reduce((r, tour) => {
        if (tour.date !== date) {
            return r;
        }
        return { ...r, [tour.truck_id]: true };
    }, {} as { [id: string]: boolean })};
}, {} as { [id: string]: { [id: string]: boolean }});
function vehicles(date: string) { 
    return Object.keys(tourCountByVehicles[date]);
}

console.log({ tourCountByVehicles });



interface MatchParams {
    id: DeliveryTour['id'];
}

interface TMSProps extends InjectedIntlProps {
    deliveryTour: DeliveryTour;
    neerbyTags: any[]; // NeerbyTag[];
    matchedRoutes: any[]; // GeoJson[];
}

interface TMSState {
    showDeliveryManDetailsDrawer: boolean;
    vehicle: string;
    date: string;
}

class TMS extends React.Component<TMSProps, TMSState> {
    public refreshInterval: number = 0;
    public deliveryTourSiderRef = React.createRef<typeof DeliveryTourSider>();

    public state: TMSState = {
        showDeliveryManDetailsDrawer: false,
        vehicle: vehicles(dates[0])[0],
        date: dates[0],
    };

    public componentDidMount() {
        // const { fetchDeliveryTour, match } = this.props;

        // fetchDeliveryTour(match.params.id);
        // this.refreshInterval = window.setInterval(() => {
        //     fetchDeliveryTour(match.params.id);
        // }, 30000);
        window.scrollTo(0, 0);
        document.querySelector('body')!.classList.add('no-scroll');
    }

    public componentWillUnmount() {
        document.querySelector('body')!.classList.remove('no-scroll');
        window.clearInterval(this.refreshInterval);
    }

    public onTaskClick = (task: Delivery) => {
        if (this.deliveryTourSiderRef.current) {
            // @ts-ignore
            this.deliveryTourSiderRef.current.getWrappedInstance().onTaskClick(task);
        }
    }

    public onClickDeliveryManDetailsButton = () => {
        this.setState({ showDeliveryManDetailsDrawer: true });
    }

    public onCloseDeliveryManDetailsDrawer = () => {
        this.setState({ showDeliveryManDetailsDrawer: false });
    }

    public onVehicleChange(vehicle: string) {
        this.setState({ vehicle });
    }

    public onDateChange(date: string) {
        if (vehicles(date).includes(this.state.vehicle)) {
            this.setState({ date });
        } else {
            this.setState({ date, vehicle: vehicles(date)[0] });
        }
    }

    public renderHeaderInfos = (deliveryTour: TMSProps['deliveryTour']) => {
        if (deliveryTour) {
            return (
                <div id="delivery-tour-details-header">
                    {/* <span className="delivery-tour-date">
                        <FormattedDate value={deliveryTour.date} />
                    </span> */}
                    <Select value={this.state.date} onChange={v => this.onDateChange(v)} style={{ minWidth: 150 }}>
                        {dates.sort((d1, d2) => {
                            return d1.localeCompare(d2);
                        }).map(date => (
                            <Select.Option value={date}>
                                <FormattedDate value={moment(date).toISOString()} />
                            </Select.Option>
                        ))}
                    </Select>
                    <Select value={this.state.vehicle} onChange={v => this.onVehicleChange(v)} style={{ minWidth: 150 }}>
                        <Select.Option value="all">Tous</Select.Option>
                        {vehicles(this.state.date).sort((v1, v2) => {
                            const [ name1, number1 ] = v1.split('_#');
                            const [ name2, number2 ] = v1.split('_#');
                            return name1.localeCompare(name2) || number1.localeCompare(number2);
                        }).map(vehicle => (
                            <Select.Option value={vehicle}>{vehicle}</Select.Option>
                        ))}
                    </Select>
                    <Button
                        type="primary"
                        size="small"
                        disabled
                    >
                        <IconDeliveryTour />
                        <FormattedMessage {...DeliveryTourMessages.deliveryManInformations} />
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { showDeliveryManDetailsDrawer, vehicle, date } = this.state;
        const { deliveryTour, neerbyTags, matchedRoutes, intl } = this.props;
        const headerLinks = {
            [getRoute(intl.locale as Languages, RoutePathName.deliveryTourList)]:
                intl.formatMessage(listMessages.contentHeaderNavItemList),
            '#': deliveryTour ? `N°${deliveryTour.label}` : '',
        };

        const tour = {
            ...deliveryTour,
            agency: {
                reference: deliveryTourSample[0].warehouse.warehouse_id,
                name: deliveryTourSample[0].warehouse.warehouse_id,
                coordinates: {
                    latitude: Number(deliveryTourSample[0].warehouse.lat),
                    longitude: Number(deliveryTourSample[0].warehouse.lon),
                },
            },
            deliveries: deliveryTourSample.flatMap(sampleTour => {
                if (date !== sampleTour.date) {
                    return [];
                }
                if (vehicle !== 'all' && vehicle !== sampleTour.truck_id) {
                    return [];
                }
                return sampleTour.deliveries.map(d => ({
                    reference: `DEMO_${d.delivery_id}`,
                    type: DeliveryType.basic,
                    source: 'internet',
                    status: DeliveryStatus.delivered,
                    date: new Date().toISOString(),
                    timeframe: {
                        from: moment(d.arrival_hour, 'HH:mm').toISOString(),
                        to: moment(d.exit_hour, 'HH:mm').toISOString(),
                    },
                    contact: {
                        name: `Livraison Nº${d.delivery_id} - ${d.city} #${d.shop_id}`,
                    },
                    address: {
                        city: `Magasin #${d.shop_id} - ${d.city}`,
                        coordinates: {
                            latitude: Number(d.lat),
                            longitude: Number(d.lon),
                        },
                    } as Address,
                    parcels: d.load.map(load => ({
                        type: loadType[load.goods] || loadType[load.type] || ParcelType.other,
                        reference: `${load.order_id}`,
                        label: `${load.palets}x ${load.goods}`,
                        number: load.order_id,
                        meta: {},
                    })),
                    items: d.load.map(load => ({
                        parcel: `${load.order_id}`,
                        source: DeliveryItemSource.order,
                        status: DeliveryItemStatus.delivered,
                        type: DeliveryItemType.parcel,
                        reference: `${load.order_id}`,
                        label: `${load.palets}x ${load.goods}`,
                        isEditable: false,
                    })),
                    discountReferences: [],
                    paymentMethods: [],
                    payments: [],
                    attachedDeliveries: [],
                    relatedDeliveryTours: [],
                    skipPreparation: false,
                    comment: {},
                    signature: {
                        registeredAt: new Date().toISOString(),
                    },
                    tags: [],
                    meta: {},
                    vehicles: [ sampleTour.truck_id ],
                }))
            }).sort((d1, d2) => {
                return d1.timeframe.from.localeCompare(d2.timeframe.from);
            }),
        };


        // export interface Parcel {
        //     id?: string;
        //     reference: string;
        //     label?: string;
        //     number?: number;
        //     type: ParcelType;
        //     tracking?: string;
        //     trackingPartner?: string;
        //     meta: { [key: string]: any };
        // }

// export enum DeliveryItemType {
//     product       = 'product',
//     parcel        = 'parcel',
//     extra         = 'extra',
//     other         = 'other',
// }

// export enum ParcelType {
//     fresh         = 'fresh',
//     frozen        = 'frozen',
//     grocery       = 'grocery',
//     multiDelivery = 'multiDelivery',
//     other         = 'other',
// }

        console.log('tour.deliveries:', tour.deliveries)
        const colors = [
            [104, 75, 255],
            [77, 166, 255],
            [225, 77, 255],
            [77, 255, 106],
        ]

console.log('date:', date)

        let tourNumber = 0;
        const sampleRoutes = deliveryTourSample.flatMap(sampleTour => {
            if (date !== sampleTour.date) {
                return [];
            }
            if (vehicle !== 'all' && vehicle !== sampleTour.truck_id) {
                return [];
            }
            const color = colors[tourNumber] || [
                Math.floor(255 * Math.random()),
                Math.floor(255 * Math.random()),
                Math.floor(255 * Math.random()),
            ];
            const paths = sampleTour.deliveries.map(d => d.path).filter(Boolean);
            if (sampleTour.warehouse && sampleTour.warehouse.return_path) {
                paths.push(sampleTour.warehouse.return_path);
            }
            if (paths.length > 0) {
                tourNumber += 1;
            }
            return paths.map(p => ({
                type: 'LineString',
                coordinates: flexiblePolyline.decode(p).polyline.map(([lat, lon]) => [lon, lat]),
                color,
            } as GeoJson & { color: any }));
        }).filter(Boolean);
        console.log('sampleRoutes:', sampleRoutes)


        // const routes = routePolyline.routes.flatMap(route => {
        //     if (date !== route.date) {
        //         return [];
        //     }
        //     if (vehicle !== 'all' && vehicle !== route.vehicle) {
        //         return [];
        //     }
        //     return route.sections.map(s => {
        //         console.log('flexiblePolyline:', flexiblePolyline.decode(s.polyline))
        //         const data = polyline.toGeoJSON(s.polyline) as GeoJson;
        //         const data2 = {
        //             type: 'LineString',
        //             coordinates: flexiblePolyline.decode(s.polyline).polyline.map(([lat, lon]) => [lon, lat]),
        //         } as GeoJson;
        //         console.log('data:', data)
        //         console.log('data2:', data2)
        //         // return data;
        //         return data2;
        //     })
        // })
        // console.log('routes:', routes)



        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                    {this.renderHeaderInfos(deliveryTour)}
                </Header>
                <Content id="delivery-tour-details">
                    <DeliveryTourMap
                        deliveryTour={tour}
                        neerbyTags={neerbyTags}
                        // matchedRoutes={matchedRoutes}
                        matchedRoutes={sampleRoutes.reverse()}
                        onTaskClick={this.onTaskClick}
                        loading={false}
                    />
                    <DeliveryTourSider
                        deliveryTour={tour}
                        loading={false}
                        // @ts-ignore
                        ref={this.deliveryTourSiderRef}
                    />
                    <DeliveryTourDeliveryManDetailsDrawer
                        deliveryTour={tour}
                        onClose={this.onCloseDeliveryManDetailsDrawer}
                        visible={showDeliveryManDetailsDrawer}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: MainReducerState /*, { match }: TMSProps */ ) => ({
    // neerbyTags: getNeerbyTagsByDeliveryTourId(state, match.params.id),
    // matchedRoutes: getMatchedRoutesByDeliveryTourId(state, match.params.id),
    // deliveryTour: getDeliveryTourById(state, match.params.id),
    // reads: getReads(state),
});

export default injectIntl(connect(
    mapStateToProps,
    // { fetchDeliveryTour: DeliveryToursActions.details },
)(TMS));
