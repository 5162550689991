import { Languages } from './components/IntlProvider';

export enum RoutePathName {
    agenciesList = 'agenciesList',
    customer = 'customer',
    customersList = 'customersList',
    dashboard = 'dashboard',
    deliveryMenList = 'deliveryMenList',
    deliveryMan = 'deliveryMan',
    deliveryTourList = 'deliveryTourList',
    deliveryTour = 'deliveryTour',
    home = 'home',
    login = 'login',
    notFound = 'notFound',
    organizationsList = 'organizationsList',
    roles = 'roles',
    unauthorized = 'unauthorized',
    usersList = 'usersList',
    monitoring = 'monitoring',
    tms = 'tms',
}

export type Routes = {
    [key in Languages]: {
        [r in RoutePathName]: string;
    };
};

export const routes: Routes = {
    [Languages.en]: {
        [RoutePathName.agenciesList]: '/agencies',
        [RoutePathName.customer]: '/customers/:id',
        [RoutePathName.customersList]: '/customers',
        [RoutePathName.dashboard]: '/dashboard',
        [RoutePathName.deliveryMan]: '/delivery-men/:id',
        [RoutePathName.deliveryMenList]: '/delivery-men',
        [RoutePathName.deliveryTourList]: '/delivery-tours',
        [RoutePathName.deliveryTour]: '/delivery-tours/:id',
        [RoutePathName.home]: '/',
        [RoutePathName.login]: '/login',
        [RoutePathName.notFound]: '/404',
        [RoutePathName.organizationsList]: '/organizations',
        [RoutePathName.roles]: '/roles',
        [RoutePathName.unauthorized]: '/unauthorized',
        [RoutePathName.usersList]: '/users',
        [RoutePathName.monitoring]: '/monitoring',
        [RoutePathName.tms]: '/tms',
    },
    [Languages.fr]: {
        [RoutePathName.agenciesList]: '/agences',
        [RoutePathName.customer]: '/clients/:id',
        [RoutePathName.customersList]: '/clients',
        [RoutePathName.dashboard]: '/tableau-de-bord',
        [RoutePathName.deliveryMan]: '/livreurs/:id',
        [RoutePathName.deliveryMenList]: '/livreurs',
        [RoutePathName.deliveryTourList]: '/tournees',
        [RoutePathName.deliveryTour]: '/tournees/:id',
        [RoutePathName.home]: '/',
        [RoutePathName.login]: '/connexion',
        [RoutePathName.notFound]: '/404',
        [RoutePathName.organizationsList]: '/organisations',
        [RoutePathName.roles]: '/roles',
        [RoutePathName.unauthorized]: '/acces-non-autorise',
        [RoutePathName.usersList]: '/utilisateurs',
        [RoutePathName.monitoring]: '/monitoring',
        [RoutePathName.tms]: '/tms',
    },
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path based on locale
export const getRawRoute = (locale: Languages, path: RoutePathName) => {
    if (!Object.prototype.hasOwnProperty.call(routes, locale)) {
        console.error(`[getRawRoute] Locale "${locale}" not found, using default "en" locale instead.`);
        if (!Object.prototype.hasOwnProperty.call(routes.en, path)) {
            console.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
            return '/404';
        } else {
            return routes[Languages.en][path];
        }
    } else if (!Object.prototype.hasOwnProperty.call(routes[locale], path)) {
        console.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[locale][path];
    }
};

// returns real-world path based on locale
export const getRoute = (locale: Languages, path: RoutePathName, params?: RouteParams) => {
    let route = getRawRoute(locale, path);

    if (params && Object.keys(params).length) {
        Object.keys(params).forEach((routeParam: RouteParams['param']) => {
            const search = new RegExp(`:${routeParam}`);
            if (search.test(route)) {
                route = route.replace(search, `${params[routeParam]}`);
            } else {
                console.warn(`[getRoute] Route param not found in route "${route}", skipping param.`);
            }
        });
    }

    return route;
};
