import { Layout } from 'antd';
import * as React from 'react';
import { UpdateAvailable, UpdateActivatedReload } from 'react-workbox';

import Sidebar from './Sidebar';
import UpdateNotification from './UpdateNotification';

const MainLayout: React.SFC = ({ children, ...rest }) => (
    <Layout id="main-layout" {...rest}>
        <UpdateAvailable>
            <UpdateNotification />
        </UpdateAvailable>
        <UpdateActivatedReload />
        <Sidebar />
        <Layout>
            {children}
        </Layout>
    </Layout>
);

export default MainLayout;
