import * as React from 'react';
import { Button, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import GenericMessages from '../locale/Generic.messages';

interface UpdateNotificationProps {
    isUpdateAvailable?: boolean;
}

class UpdateNotification extends React.Component<UpdateNotificationProps> {
    public componentDidUpdate(prevProps: UpdateNotificationProps) {
        console.warn('[UpdateNotification]', prevProps, this.props);
        if (!prevProps.isUpdateAvailable && this.props.isUpdateAvailable) {
            console.warn('[UpdateNotification] update available');

            const key = `open${Date.now()}`;
            const btn = (
                <Button
                    icon="reload"
                    onClick={this.onClickUpdate}
                    size="small"
                    type="primary"
                >
                    <FormattedMessage {...GenericMessages.update} />
                </Button>
            );
            notification.open({
                duration: 0,
                message: <FormattedMessage {...GenericMessages.updateTitle} />,
                description: <FormattedMessage {...GenericMessages.updateMessage} />,
                btn,
                key,
            });
        }
    }

    public onClickUpdate = async () => {
        if (navigator.serviceWorker) {
            const registration = await navigator.serviceWorker.getRegistration();

            if (registration && registration.waiting) {
                console.warn('onClickUpdate');

                registration.waiting.postMessage('skipWaiting');
            }
        }
    }

    public render() {
        return null;
    }
}

export default UpdateNotification;
